html, body {
  height: 100%;
  font-family: "Roboto"
}
@media only screen and (min-width: 700px){
    .card-custom {
        width: 680px !important;
    }
}

[type="checkbox"].filled-in:checked+span:not(.lever):after{
  border: 2px solid #6B69CF;
  background-color: #6B69CF
}



.alice-carousel__dots {
  margin-bottom: 0;
  padding-bottom: 30
}

.row {
  margin-bottom: 0px;
  padding-bottom: 20px;
}
  /* total width */
.react-datepicker-wrapper {
  width: 100%;
}

::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}
 
::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background:#6d6d6d; 
}

.brand-logo {
  padding-top: 7px !important;
  padding-left: 10px !important;
}

.perfilParent {
  padding-top: 7px !important;
}

@media (max-width: 992px) {
  .brand-logo {
    padding-top: 3px !important;
    padding-left: 0px !important;
  }
}

.onlySideMenuDevices {
  display: none;
}

@media (max-width: 992px) {
  .onlySideMenuDevices {
    display: block;
  }
}

.nav-wrapper {
  background-color: rgb(4, 122, 178);
}

@media (min-width: 992px) {
  .btn-file-adjust {
    width: 31.6635% !important;
    float: left !important;
  }
  .label-file-adjust {
    width: 31.6635% !important;
    text-align: center !important;
  }
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.pointer {
 cursor: pointer;
}


.maisEventos {
  margin-left: 5%;
  margin-top: 30px;
}

@media (max-width: 992px) {
  .maisEventos {
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 30px;
  }
}

@media (max-width: 992px) {
  .faleConosco {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

/* .alice-carousel ul li div {
  padding-left: 20px;
  padding-right: 20px;
} */

/* .alice-carousel__stage-item {
  padding-left: 20px;
  padding-right: 20px;
} */


.rowPolitica {
  margin-top: 100px
}

@media (max-width: 992px) {
  .rowPolitica {
      margin-top: 0px
  }
}

strong { font-weight: bold; }



